import React, { useState } from "react";
import styles from "./header.module.scss";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import { images } from "../utils/utils";
import { IoMenu } from "react-icons/io5";
import { Drawer } from "antd";

const Header = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <div className={styles.header_main}>
      <div className={styles.header_content}>
        <div className={styles.header_logo} onClick={() => navigate("/")}>
          SASVAAT
        </div>
        <div style={{ flex: 1 }} />
        <div
          className={styles.header_link}
          onClick={() => navigate("/why-sasvaat")}
        >
          Why Sasvaat?
        </div>
        <div className={styles.header_link} onClick={() => navigate("/career")}>
          Careers
        </div>
        <div className={styles.btn_div}>
          <Button onClick={() => navigate("/contact-us")}>Contact Us</Button>
        </div>
        <IoMenu
          size={30}
          className={styles.menu_icon}
          color="#09069b"
          onClick={() => showDrawer()}
        />
      </div>

      <img src={images.headerbg} className={styles.header_headerbg} />
      <Drawer onClose={onClose} open={open}>
        <div
          className={styles.drawer_link}
          onClick={() => {
            onClose();
            navigate("/why-sasvaat");
          }}
        >
          Why Sasvaat?
        </div>
        <div
          className={styles.drawer_link}
          onClick={() => {
            onClose();
            navigate("/career");
          }}
        >
          Careers
        </div>
        <div
          className={styles.drawer_link}
          onClick={() => {
            onClose();
            navigate("/contact-us");
          }}
        >
          Contact Us
        </div>
      </Drawer>
    </div>
  );
};

export default Header;
