import React from "react";
import styles from "./whysasvaat.module.scss";
import { images } from "../utils/utils";

const WhySasvaat = () => {
  return (
    <div className={styles.w_main}>
      <div className={styles.w_img_div}>
        <img className={styles.w_img} src={images.img8} />
      </div>
      <div className={styles.h_content_row}>
        <div className={styles.h_content_row_text1}>
          <div className={styles.h_content_title}>How we are different</div>
          <div className={styles.h_content_text1}>
            In the traditional outsourcing model, clients will not have control
            over resource hiring, compensation and organization/team structure.
            Many middle level layers to communicate with actual developers. Not
            close monitoring of the workspace. Clients can't dictate workspace
            culture and policies.
          </div>
          <div className={styles.h_content_title}>With Sasvaat</div>
          <li className={styles.h_content_text1}>
            Build your own team with your terms
          </li>
          <li className={styles.h_content_text1}>
            Bring your culture and values to your team
          </li>
          <li className={styles.h_content_text1}>
            Full control on hiring resources
          </li>
          <li className={styles.h_content_text1}>
            Your resources and your money - decide the compensation of your
            associates.
          </li>
          <li className={styles.h_content_text1}>
            Your org structure - you have full freedom on deciding the org
            structure of your Team.
          </li>
          <li className={styles.h_content_text1}>
            Focus only on core work, while we take care of all other work.
          </li>
          <li className={styles.h_content_text1}>Pay-as- you-go model.</li>
        </div>
        <div className={styles.h_content_row_img_div1}>
          <img className={styles.h_content_row_img} src={images.img4} />
        </div>
      </div>
    </div>
  );
};

export default WhySasvaat;
