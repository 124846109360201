import React, { useState } from "react";
import styles from "./contactus.module.scss";
import Button from "../components/Button";
import { images, validateEmail, validatePhone } from "../utils/utils";
import { Oval } from "react-loader-spinner";
import { toast } from "react-toastify";

const ContactUs = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const onSendData = async () => {
    if (
      !data.name ||
      !data.company ||
      !data.email ||
      !data.phone ||
      !data.message
    ) {
      toast.info("All fields are required", { toastId: 1 });
      return;
    }
    if (!validateEmail(data.email)) {
      toast.info("Invailid email address", { toastId: 1 });
      return;
    }
    if (!validatePhone(data.phone)) {
      toast.info("Invailid phone number", { toastId: 1 });
      return;
    }
    try {
      setLoading(true);
      const body = new FormData();
      body.append("name", data.name);
      body.append("company", data.company);
      body.append("email", data.email);
      body.append("mobile", data.phone);
      body.append("message", data.message);
      const url = "https://sasvaat.com/api/sasvaat_contact_mail.php";
      const response = await fetch(url, {
        method: "POST",
        body: body,
      });
      const res = await response.json();
      console.log("res", res);
      setLoading(false);
      if (res.success) {
        setData({
          ...data,
          name: "",
          company: "",
          email: "",
          phone: "",
          message: "",
        });
        toast.success(res.Message, { toastId: 1 });
      } else {
        toast.error(res.Message, { toastId: 1 });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className={styles.c_main}>
      <div className={styles.c_img_div}>
        <img className={styles.c_img} src={images.img9} />
      </div>
      <div className={styles.c_form_div}>
        <div className={styles.c_heading}>Contact us</div>
        <div className={styles.c_row}>
          <input
            className={styles.input_box}
            value={data.name}
            onChange={(e) =>
              setData({
                ...data,
                name: e.target.value
                  .trimStart()
                  .replace(/[^a-zA-Z0-9 ]/g, "")
                  .replace(/^[0-9]/, "")
                  .replace(/\s{2,}/g, " "),
              })
            }
            placeholder="Full name"
          />
          <input
            className={styles.input_box}
            placeholder="Company"
            value={data.company}
            onChange={(e) =>
              setData({
                ...data,
                company: e.target.value
                  .trimStart()
                  .replace(/[^a-zA-Z0-9 ]/g, "")
                  .replace(/^[0-9]/, "")
                  .replace(/\s{2,}/g, " "),
              })
            }
          />
        </div>
        <div className={styles.c_row}>
          <input
            className={styles.input_box}
            placeholder="Work email"
            value={data.email}
            onChange={(e) => setData({ ...data, email: e.target.value.trim() })}
          />
          <input
            className={styles.input_box}
            placeholder="Phone"
            value={data.phone}
            maxLength={10}
            onChange={(e) =>
              setData({
                ...data,
                phone: e.target.value.trim().replace(/[^0-9 ]/g, ""),
              })
            }
          />
        </div>
        <div className={styles.c_row}>
          <textarea
            className={styles.input_area}
            placeholder="How can we help you?"
            value={data.message}
            onChange={(e) =>
              setData({
                ...data,
                message: e.target.value
                  .trimStart()
                  .replace(/[^a-zA-Z0-9 ]/g, "")
                  .replace(/^[0-9]/, "")
                  .replace(/\s{2,}/g, " "),
              })
            }
          />
        </div>
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            flex: 1,
            marginTop: "20px",
          }}
          onClick={() => onSendData()}
        >
          <Button>
            {loading ? (
              <Oval
                height="25"
                width="25"
                radius="9"
                color="#fff"
                ariaLabel="loading"
                strokeWidth={5}
              />
            ) : (
              "Send"
            )}
          </Button>
        </div>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.649405552104!2d77.69688987526732!3d12.994261587323196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae11755807bd47%3A0x9ee8589c830d754e!2s91springboard%20ITPL%20Main%20Road%2C%20Mahadevapura!5e0!3m2!1sen!2sin!4v1708346557408!5m2!1sen!2sin"
        width="100%"
        // height="550"
        // style={{ border: 0, marginBlock: "1.5rem" }}
        className={styles.map}
        allowfullscreen={true}
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default ContactUs;
