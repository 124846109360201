import React from "react";
import styles from "./button.module.scss";

const Button = ({ children, large, onClick }) => {
  return (
    <div
      className={styles.button_main}
      onClick={onClick}
      style={{ paddingInline: large ? 40 : 20, paddingBlock: large ? 10 : 7 }}
    >
      {children}
    </div>
  );
};

export default Button;
