import React from "react";
import styles from "./career.module.scss";
import { images } from "../utils/utils";

const Career = () => {
  return (
    <div className={styles.w_main}>
      <div className={styles.w_img_div}>
        <img className={styles.w_img} src={images.img10} />
      </div>
      <div className={styles.h_content_title_main}>
        Join Our Team as an Intern: Dive into the World of Cutting-Edge
        Technologies
      </div>
      <div className={styles.h_content_title_main1}>
        Are you a self-taught, self-motivated individual with a passion for
        technology?
        <br />
        Are you eager to explore the realms of computer vision, machine
        learning, and mobile app development?
        <br />
        <b>
          If so, we invite you to embark on an enriching journey with us as an
          intern.
        </b>
        {/* </div> */}
      </div>
      <div className={styles.h_content_row}>
        <div className={styles.h_content_row_text1}>
          <div className={styles.h_content_title}>About Us</div>
          <div className={styles.h_content_text1}>
            At Sasvaat, we thrive on innovation and creativity. Our team is
            dedicated to pushing the boundaries of technology, and we're seeking
            enthusiastic interns to join us in this mission.
          </div>
          <div className={styles.h_content_title}>What You'll Do</div>
          <div className={styles.h_content_text1}>
            As an intern with us, you'll have the opportunity to immerse
            yourself in cutting-edge technologies. From developing algorithms
            for computer vision applications to exploring the intricacies of
            machine learning models, you'll be at the forefront of technological
            advancement. Additionally, you'll delve into the dynamic world of
            mobile app development, gaining hands-on experience in creating
            innovative solutions for real-world problems.
          </div>
          <div className={styles.h_content_title}>Who We're Looking For</div>
          <div className={styles.h_content_text1}>
            We're seeking passionate candidates who are driven by curiosity and
            a desire to learn. Whether you're a seasoned programmer or a budding
            enthusiast, if you have a knack for problem-solving and a hunger for
            knowledge, we want to hear from you. While formal education is
            valuable, we believe that true potential knows no bounds, and we
            welcome individuals from all backgrounds and levels of experience.
          </div>
        </div>
        <div className={styles.h_content_row_img_div1}>
          <img className={styles.h_content_row_img} src={images.img11} />
        </div>
      </div>
      <div style={{ paddingInline: "30px" }}>
        <div className={styles.h_content_title}>Why Join Us</div>
        <li className={styles.h_content_text1}>
          <b>Hands-On Experience:</b> Gain practical experience working on
          projects that utilize cutting-edge technologies.
        </li>
        <li className={styles.h_content_text1}>
          <b>Mentorship:</b> Receive guidance and support from experienced
          professionals who are dedicated to helping you succeed.
        </li>
        <li className={styles.h_content_text1}>
          <b>Opportunity for Growth:</b> Explore your interests and expand your
          skill set in a dynamic and collaborative environment.
        </li>
        <li className={styles.h_content_text1}>
          <b>Impactful Work:</b> Contribute to projects that have the potential
          to make a real difference in the world.
        </li>
        <div className={styles.h_content_title} style={{ marginTop: 20 }}>
          How to Apply
        </div>
        <div className={styles.h_content_text1} style={{ marginBottom: 20 }}>
          Ready to take the next step in your journey?
          <br />
          To apply for our internship program, please send your CV to{" "}
          <b>info@sasvaat.com.</b> We can't wait to hear from you!
        </div>
      </div>
    </div>
  );
};

export default Career;
