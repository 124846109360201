export const images = {
  headerbg: require("../assets/images/headerbg.jpeg"),
  img1: require("../assets/images/img1.jpg"),

  img4: require("../assets/images/img4.jpg"),
  img6: require("../assets/images/img6.jpeg"),
  img8: require("../assets/images/img8.jpg"),
  img9: require("../assets/images/img9.jpg"),
  img10: require("../assets/images/img10.jpg"),
  img11: require("../assets/images/img11.jpg"),
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
export const validatePhone = (phone) => {
  return String(phone)
    .toLowerCase()
    .match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im);
};
