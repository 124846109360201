import React from "react";
import styles from "./home.module.scss";
import { images } from "../utils/utils";

const Home = () => {
  return (
    <div className={styles.h_main}>
      <div className={styles.h_img_div}>
        <img className={styles.h_img} src={images.img1} />
        <div className={styles.h_img_content}>
          <div className={styles.h_img_content_logo}>SASVAAT</div>
          <div className={styles.h_img_content_heading}>
            We travel with you in building your vision.
          </div>
        </div>
      </div>
      <div className={styles.h_content_row}>
        <div className={styles.h_content_row_text}>
          <div className={styles.h_content_text}>
            Welcome to SASVAAT, where we harness the power of India's booming
            economy and global outsourcing trends to provide exceptional
            services to our clients worldwide.
          </div>
          <div className={styles.h_content_title}>India's Economic Growth</div>
          <div className={styles.h_content_text1}>
            India stands at the forefront of the global economy, boasting a
            remarkable growth trajectory over the past few decades. With a
            burgeoning population, a vibrant entrepreneurial ecosystem, and a
            commitment to technological advancement, India has emerged as a
            powerhouse in various industries, including technology, finance,
            healthcare, and more.
          </div>
        </div>
        <div className={styles.h_content_row_img_div}>
          <img className={styles.h_content_row_img} src={images.img6} />
        </div>
      </div>
      <div className={styles.h_content_row}>
        <div className={styles.h_content_row_img_div1}>
          <img className={styles.h_content_row_img} src={images.img4} />
        </div>
        <div className={styles.h_content_row_text1}>
          <div className={styles.h_content_title}>
            The Outsourcing Advantage
          </div>
          <div className={styles.h_content_text1}>
            Outsourcing has played a pivotal role in India's economic success
            story. By leveraging India's vast talent pool, cost-effective
            solutions, and robust infrastructure, businesses worldwide can
            streamline operations, enhance efficiency, and drive growth.
          </div>
          <div
            className={styles.h_content_text1}
            style={{ fontWeight: "bold" }}
          >
            Outsourcing to India offers several key benefits:
          </div>
          <li className={styles.h_content_text1}>
            <b>Access to Skilled Talent:</b> India is home to a diverse pool of
            highly skilled professionals across various domains, including IT,
            customer service, finance, digital marketing, and more. Our company
            taps into this talent pool to deliver top-notch services tailored to
            our clients' specific needs.
          </li>
          <li className={styles.h_content_text1}>
            <b>Cost-Effectiveness:</b> Outsourcing to India enables businesses
            to significantly reduce operational costs without compromising on
            quality. At sasvaat, we offer competitive pricing structures that
            ensure maximum value for our clients' investments.
          </li>
        </div>
      </div>
      {/* <div className={styles.h_content_row}>
        <div className={styles.h_content_row_text1}>
          <div className={styles.h_content_title}>How we are different</div>
          <div className={styles.h_content_text1}>
            In the traditional outsourcing model, clients will not have control
            over resource hiring, compensation and org/team structure. Many
            middle level layers to communicate with actual developers. Not close
            monitoring of the workspace. Clients can't dictate workspace culture
            and policies.
          </div>
        </div>
        <div className={styles.h_content_row_img_div1}>
          <img className={styles.h_content_row_img} src={images.img4} />
        </div>
      </div> */}
      <div className={styles.space} />
    </div>
  );
};

export default Home;
