import React from "react";
import styles from "./footer.module.scss";
import { IoMdCall, IoIosMail } from "react-icons/io";
import {
  FaTwitterSquare,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className={styles.footer_main}>
      <div className={styles.footer_logo}>SASVAAT</div>
      <div className={styles.footer_content_div}>
        <div className={styles.footer_heading_div}>
          <div className={styles.footer_heading}>
            SASVAAT SOFTWARE TECHNOL PRIVATE LIMITED
            <br />
            {/* 91 Springboard, Mahadevapura, Bangalore-560048 India. */}
            91 springboard Outer Ring Road (ORR Mahadevapura Service Lane,
            512/10, Outer Ring Rd), Mahadevapura, Bengaluru, Karnataka 560048,
            India
          </div>
          <div className={styles.line} />
        </div>
        <div style={{ flex: 1 }}></div>
        <div
          style={{
            alignSelf: "flex-start",
          }}
        >
          {/* <div className={styles.footer_row}>
            <IoMdCall color="#5B5050" size={20} />
            <div className={styles.footer_title}>( +91 ) 456 7890</div>
          </div> */}
          <div className={styles.footer_row}>
            <IoIosMail color="#5B5050" size={20} />
            <div className={styles.footer_title}>admin@sasvaat.com</div>
          </div>
        </div>
      </div>

      <div className={styles.footer_box}>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <FaTwitterSquare size={20} className={styles.f_icons} />
          <FaFacebook className={styles.f_icons} size={20} />
          <FaLinkedin className={styles.f_icons} size={20} />
          <FaInstagram className={styles.f_icons} size={20} />
        </div>
        {/* <div className={styles.footer_title4}>
            © Copyright 2011 - 2024 The Executive Centre. All rights reserved
          </div> */}
      </div>
    </div>
  );
};

export default Footer;
