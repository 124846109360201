import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./screens/Home";
import Career from "./screens/Career";
import WhySasvaat from "./screens/WhySasvaat";
import ContactUs from "./screens/ContactUs";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="/career" element={<Career />} />
        <Route path="/why-sasvaat" element={<WhySasvaat />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Route>
    </Routes>
  );
};

export default App;
