import React from "react";
import styles from "./layout.module.scss";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

const Layout = () => {
  return (
    <div className={styles.layout_main}>
      <div className={styles.layout_header}>
        <Header />
      </div>
      <div className={styles.layout_body}>
        <Outlet />
      </div>
      <div className={styles.layout_footer}>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
